import React from 'react'
import { Container, Row, Col, Hidden } from 'react-grid-system';

function Home(){
	return( 
	<Container id="header" fluid>
	
	<Container>
	<Row id="headline">
		
	 <Col sm={12}><h1>Creative Work</h1></Col>
	 <Col sm={12}><p id="strap">front-end design and development, print design, illustration, video, multimedia, creative direction</p></Col>

	</Row>
	</Container>
	</Container>
	)
}

export default Home;