import React from 'react'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import ReactLogo from './assets/reactjs.svg'

function Home(){
	return( 
	<>
	<Container id="footer" fluid>
	<Container>
	<Row>		
	 <Col sm={12} md={7}><p>&copy; {new Date().getFullYear()} Daniel Goebel</p></Col>
	 <Col id="reactlogo" xs={2} sm={2} md={1}><img src={ReactLogo} /></Col>
	 <Col id="reactinfo" xs={10} sm={10} md={4}><p>This site created 100% with <a href="https://reactjs.org/" target="_blank">React.js</a>! Learning React had been on my list for a while and I have to say it was fun! I'm a life-long learner. Whether it's a new scripting language, an illustration trick, or the octave range of a bassoon—I love learning. </p></Col>

	</Row>
	</Container>
	</Container>
	</>
	)
}

export default Home;