import React from 'react'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import dgimage from './assets/danielgoebel.jpg'

function Home(){
	return( 
	<Container id="about" fluid>
	<Container>
	<Row  >
		
	 <Col><h2>About</h2></Col>
	 </Row>
	 <Row>
	 <Col xs={12} sm={8}>
	 	<p>You've made it this far. Congratulations! Unlike others who stopped at the "work" section, you're in for something special. That's right, a breif bio. Take it away. . .</p>


<p>I'm a creative person. I make websites. I make print materials, marketing collateral, videos, and lots of other stuff, too.</p>

<p>But that's not all. I make ideas. Creative ideas. Ideas that find solutions and resonate with the audience.</p>

<h3>Education:</h3>
<h4>The University of Chicago</h4>
<p>Graham School: Project Management Certificate<br />
	Chicago Booth Davis Center for Leadership: Course Completion
</p>

<h4>Berklee College of Music</h4>
<p>Music Composition, concetration in Filmscoring, <em>summa cum laude</em></p>
	 </Col>
	 <Col xs={12} sm={4}>
	 <img src={dgimage} />
	 </Col>

	</Row>
	</Container>
	</Container>
	)
}

export default Home;