import React, {useReducer, useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {useSpring, useTrail, animated} from 'react-spring';
import axios from "axios";
import Data from "./WorkList.json";
import Scroll from 'react-scroll';
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faVideo, faPlay, faFile, faPrint, faDesktop, faIcons, faPodcast, faBezierCurve, faClose, faBook, faDiceD20, faFileLines, faPenFancy} from "@fortawesome/free-solid-svg-icons";


const projects = Data;
const items = [`one`, `two`, `three`];
const element = <FontAwesomeIcon icon={faHome} />
const website = <FontAwesomeIcon icon={faDesktop} />
const print = <FontAwesomeIcon icon={faPrint} />
const illustration = <FontAwesomeIcon icon={faPenFancy} />
const logo = <FontAwesomeIcon icon={faDiceD20} />
const video = <FontAwesomeIcon icon={faVideo} />
const podcast = <FontAwesomeIcon icon={faPodcast} />
const other = <FontAwesomeIcon icon={faDiceD20} />
const Link = Scroll.Link


function WorkDev(){

	const closer = <FontAwesomeIcon icon={faClose} />

	const ProjectObjects = projects.map((proj, i) => ({ id: i, title: proj.title, role: proj.role, tools: proj.tools, purpose: proj.purpose, vid: proj.vid, img1: proj.img1, img2: proj.img2, img3:proj.img3, type: proj.type, video: proj.video, podcast: proj.podcast, feature: proj.feature, bgimg: proj.bgimg}));

	const [state, setstate] = useState({data:""})

	const ProjectDisplay = (data) => {
		
		setstate({id: (data.id), type: (data.type), title: (data.title), role: (data.role), purpose: (data.purpose), tools: (data.tools), vid: (data.vid), img1: (data.img1), img2: (data.img2), img3: (data.img3), video: (data.video), podcast: (data.podcast), feature: (data.feature), bgimg: (data.bgimg)})
	}

	const [hidden, setHidden] = useState(true)
	const [flip, set] = useState(false)

	const [isHide, setHide] = useState(false)
	const trail = useTrail(items.length, { opacity: isHide ? 0 : 1 })
	const toggle = () => setHide(!isHide)

	const [isVidFeature, setVidFeature] = useState(false);
	

	const props = useSpring({ 
  				to: { opacity: 1}, 
  				from: { opacity: 0},
  				transform: 'translate3d(0px,0,0) scale(1)',
  				reset: true,
                reverse: false,
                delay:0,
                config: {duration:1000},
  				})	

	//const projType = projects.map((proj) => ({type: proj.type}))
	//const projType = ProjectObjects.map( (proj) => ( this.proj.type ) )
 



	
	const iconType = (proj) => {

			switch(proj.type) {
				case "Website" : return website
				case "Illustration" : return illustration
				case "Print Design" : return print
				case "Logo" : return logo
				case "Video" : return video
				case "Podcast" : return podcast
				default: return other
			}

		 }



	const FullProjectList = () => {
		return (

		<>
		<animated.div style={props} >
		<Col> 
		<p>Here are some of my favorite projects which highlight a range of work. Included are: websites, videos, illustrations, print pieces, branding, and logos. </p>
		<Row>

		{ProjectObjects.map( (proj) => (

			<Col className="project" xs={6} sm={4} md={3} lg={2} >	


			<Link to="work" spy={true} smooth={true} duration={500} className="button" id={proj.id}  onClick={() => {set(s => !s); ProjectDisplay(proj); }} key={proj.id} style={{backgroundImage: `url(${proj.bgimg}`}}>
			<div className="overlay-block"></div>
			<p className="project-type">{iconType(proj)}</p>
			<p className="project-title">{proj.title}</p>

			   
			 </Link> 
			 </Col>
			 

		   )) }

		</Row>
		</Col>
		</animated.div>
		</>

      )
	} 
	


	const ProjectDive = (proj) => {


	


		return(

			<Container id="dive">

			<animated.div style={props}> 

			<Row class="closeup">
			
			<button id="close" onClick={() => set(s => !s)}><FontAwesomeIcon icon={faClose} /></button>

			<Col xs={12} sm={3}>
			<Col xs={12}><h3>Project:</h3><p>{state.title}</p></Col>
            <Col xs={12}><h3>My Role:</h3><p> {state.role}</p></Col>
            </Col>

            <Col xs={12} sm={7}>
			<Col xs={12}><h3>Purpose:</h3><p>{state.purpose}</p></Col>
			<Col xs={12}><h3>Tools:</h3><p>{state.tools}</p></Col>
			</Col>
			
			
			{ state.feature && state.feature.map( data => {
					return (

					<Col xs={12} sm={7}><img src={data.img1} />

					</Col>

						)
				})}
			
			{ state.video && state.video.map( data => {
				return (
					<Col xs={12} sm={7} className="videoWrapper"><iframe height="100%" src={`https://www.youtube.com/embed/${data.vidid}?rel=0&hd=1`} frameborder="0" allowfullscreen></iframe></Col>
					)

			})}

			
			{ state.podcast && state.podcast.map( data => {

				return (
					<Col xs={12} sm={5} className="podcast">

					<p>Episode 1: <a href={data.audio1} target="_blank">Building a College List</a></p>
					<p>Episode 2: <a href={data.audio2} target="_blank">College Apps Part 1</a></p>
					<p>Episode 3: <a href={data.audio3} target="_blank">College Apps Part 2</a></p>
					<p>Episode 4: <a href={data.audio4} target="_blank">Visiting College</a></p>
					<p>Episode 5: <a href={data.audio5} target="_blank">Writing College Essays</a></p>



					</Col>
					)

			})}


			<Col xs={12} sm={5}><img src={state.img2} /><img src={state.img3}/></Col>

			




            </Row>
            
            </animated.div>
            </Container>
          
		)
	}


return (

	<div>

     <Container id="work" fluid>


   {/*  <button onClick={toggle}>TOGGLE</button>
			<ul>
				{trail.map(({ opacity }, i) => {
					const item = items[i];
					return (
						<animated.li style={{ opacity }} key={item}>
							{item}
						</animated.li>
					);
				})}
			</ul>*/}

	<Container>
	 <Row>
	 <Col xs={12}><h2>Work</h2></Col>
	 </Row>



      {!flip ? <FullProjectList /> : <ProjectDive/>}


     </Container>
     </Container>
     </div>

      


	)

}
 export default WorkDev;