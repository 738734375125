import React from 'react'
import { Container, Row, Col, Hidden } from 'react-grid-system';
import  AudioPlayer from './AudioPlayer'
import tracks from "./Tracks";
import serious1 from "./assets/serious-play-1.png"
import serious2 from "./assets/serious-play-2.png"
import seriouspdf1 from "./assets/serious-play-v1.pdf"
import seriouspdf2 from "./assets/serious-play-v2.pdf"

function Home(){
	return( 
	<Container id="thisthat" fluid>
	<Container>
	<Row >
		
	 <Col><h2>This/That</h2></Col>
	 </Row>

	 <Row>
	 <Col xs={12} sm={6}>
	 	<p>Classicaly trained as a composer, music has given me the tools and techniques to become a creative thinker regardless of the project at hand. Here are a number of tracks recorded over the years.</p>
	 	<AudioPlayer tracks={tracks} />
	 </Col>
	 <Col xs={12} sm={6}>
	    <p><strong>Creativity is a muscle.</strong> The more it works, the stronger it gets. For this reason I lead my creative team in creative exercises each week&mdash;some related to real problems we are trying to solve; some just for fun. Beyond helping us brainstorm and refine ideas, it brings us closer together as a team. </p>
	    <p>Because some members of the team travel, I created these 2 handbooks in the style of an old army field manual. These are collections of different creative activities. Some are tried and true; some are my own invention. Not only does this give a nice mental break, it also keep us sharp. Download for yourself and enjoy.</p>
	    <Row>
	    <Col className="serious" xs={6}><a href={seriouspdf1} target="_blank"><img src={serious1} /></a></Col>
	 	<Col className="serious" xs={6}><a href={seriouspdf2} target="_blank"><img src={serious2} /></a></Col>
	 	</Row>
	 </Col>
	 </Row>

	</Container>
	</Container>
	)
}

export default Home;