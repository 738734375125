import React from "react";
import logo from './daniel-goebel-logo.svg';
import './App.css';
import Header from './Header';
import Navigation from './Navigation';
import Work from './Work';
import WorkDev from './WorkDev';
import Skills from './Skills';
import About from './About';
import ThisThat from './ThisThat';
import Contact from './Contact';
import Footer from './Footer';
import {Helmet} from "react-helmet";
import { Element } from 'react-scroll'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-121841249-1";

ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname);


function App() {
  return( 
    <div className="App">

       <Navigation />
       <Header id='homer' name='homer' />

       <WorkDev id='work' name='work' />
       <Skills id='skills' name='skills' />
       <ThisThat id='thisthat' name='thisthat' />
       <About id='about' name='about'/>
       <Contact id='contact' name='contact' />
       <Footer />
       
     
    </div>
);

}

export default App;
