import React, { useState} from 'react'
import { Container, Row, Col, Hidden } from 'react-grid-system';




function Home(){


	return( 

	<Container id="contact" fluid>
	<Container>
	<Row>
	 <Col><h2>Contact</h2></Col>
	 </Row>
	 <Row>
	 <Col sm={12} md={7}>
	 	<form id="contactform">
			<label for="name" style={{display: 'none'}}>Name</label>
	        <input aria-label="Name" type="text" name="name" id="name" placeholder="Your Name" />
	        <label for="email" style={{display: 'none'}}>Email</label>
	        <input aria-label="Email" type="email" id="email" name="email" placeholder="Your Email" />
	        <label for="message" style={{display: 'none'}}>Message</label>
	        <textarea aria-label="Message" name="message" id="input-msg" placeholder="Your Message"></textarea>
			<input type="submit" value="Send" />
		</form>
		<div id="result-text"></div>	
	 </Col>

	 <Col sm={12} md={5}>

	   <p>I'm feeling a little anti-social at the moment. :( </p>
	   	<p>You can always check me out on <a href="https://www.linkedin.com/in/danielgoebel/" target="_blank">LinkedIn</a></p>

	 </Col>

	</Row>
	</Container>
	</Container>
	)
}

export default Home;