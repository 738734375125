import imgSrc1 from "./assets/ratner.jpg";
import imgSrc3 from "./assets/seal.jpg";
import imgSrc2 from "./assets/stairway.jpg";
import imgSrc4 from "./assets/podcast1.jpg";
import imgSrc5 from "./assets/podcast2.jpg";
import imgSrc6 from "./assets/groove.jpg";
import imgSrc7 from "./assets/thankyou.jpg";
import imgSrc8 from "./assets/elecks.jpg";
import music1 from "./assets/music1.mp3";
import music2 from "./assets/music2.mp3";
import music3 from "./assets/music3.mp3";
import podcast10 from "./assets/podcast-10.mp3";
import asante from "./assets/asante.mp3";
import elecks from "./assets/elecks.mp3";
import groovea from "./assets/groove-a.mp3";
import podcast16 from "./assets/podcast-16.mp3";

// All of these artists are at https://pixabay.com/music/search/mood/laid%20back/
export default [
{
    title: "Podcast Interlude #10",
    artist: "D.Goebel",
    audioSrc: podcast10,
    image: imgSrc4,
    color: "#5f9fff"
  },
  {
    title: "Asante",
    artist: "D.Goebel",
    audioSrc: asante,
    image: imgSrc7,
    color: "#5f9fff"
  },
  {
    title: "The Seal Step Not",
    artist: "D.Goebel",
    audioSrc: music3,
    image: imgSrc3,
    color: "#5f9fff"
  },
  {
    title: "Elecks",
    artist: "D.Goebel",
    audioSrc: elecks,
    image: imgSrc8,
    color: "#5f9fff"
  },
  {
    title: "Groove A.",
    artist: "D.Goebel",
    audioSrc: groovea,
    image: imgSrc6,
    color: "#5f9fff"
  },
  {
    title: "Podcast Interlude #16",
    artist: "D.Goebel",
    audioSrc: podcast16,
    image: imgSrc5,
    color: "#5f9fff"
  },

  {
    title: "Ratner and the 1st Heisman",
    artist: "D.Goebel",
    audioSrc: music1,
    image: imgSrc1,
    color: "#00aeb0"
  },
  {
    title: "Waltz: Stairway to Reynolds ",
    artist: "D.Goebel",
    audioSrc: music2,
    image: imgSrc2,
    color: "#ffb77a"
  }
];
