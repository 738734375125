import React, {Component, useEffect, useState } from 'react';
import Scroll from 'react-scroll';
import { MdClose } from "react-icons/md"
import { FiMenu } from "react-icons/fi"
import logo from './daniel-goebel-logo.svg';
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system';


const Link = Scroll.Link



function Navigation(){

const [navbarOpen, setNavbarOpen] = useState(false)
const handleToggle = () => {
  setNavbarOpen(prev => !prev)
}

  const [small, setSmall] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 150)
      );
    }
  }, []);


	return( 





	<Container id="navigation" className={`${small ? "small" : ""}`} fluid>




	<Container>


	<Row>
		<Col id="logo" sm={4}>
		<img src={logo} alt="daniel goebel logo" />
		<span id="logoname"><span>Daniel</span> Goebel</span> 
		</Col>

		<Visible xs sm>
		<nav className="hamburger">
				<button onClick={handleToggle}>
				{navbarOpen ? (
    		<MdClose style={{ color: "#fff", width: "40px", height: "40px" }} />
  				) : (
    		<FiMenu style={{ color: "#7b7b7b", width: "40px", height: "40px" }} />
  				)}
		
				</button>
				<ul className={`menuNav ${navbarOpen ? " showMenu" : ""}`}>
					<li className="burger burger1">
					<Link to="work" spy={true} smooth={true} duration={500} className='navlink' activeClass='navlink-active'>Work</Link></li>
					<li className="burger burger1">
					<Link to="skills" spy={true} smooth={true} duration={500} className='navlink' activeClass='navlink-active'>Skills</Link></li>
					<li className="burger burger1">
					<Link to="thisthat" spy={true} smooth={true} duration={500} className='navlink' activeClass='navlink-active'>This/That</Link></li>
					<li className="burger burger1">
					<Link to="about" spy={true} smooth={true} duration={500} className='navlink' activeClass='navlink-active'>About</Link></li>
					<li className="burger burger1"><Link to="contactform" spy={true} smooth={true} duration={500} className='navlink' activeClass='navlink-active'>Contact</Link></li>
				</ul>
	  </nav>


		</Visible>

		<Col md={8}>
		<Hidden sm xs>


		<nav>
		<ul>
		<li>
		<Link to="work" spy={true} smooth={true} duration={500} className='navlink' activeClass='navlink-active'>Work</Link></li>
		<li><Link to="skills" spy={true} smooth={true} duration={500} className='navlink' activeClass='navlink-active'>Skills</Link></li>
		<li><Link to="thisthat" spy={true} smooth={true} duration={500} className='navlink' activeClass='navlink-active'>This/That</Link></li>
		<li><Link to="about" spy={true} smooth={true} duration={500} className='navlink' activeClass='navlink-active'>About</Link></li>
		<li><Link to="contact" spy={true} smooth={true} duration={500} className='navlink' activeClass='navlink-active'>Contact</Link></li>

		</ul>
		</nav>
		</Hidden>
		</Col>
		</Row>

	</Container>
	</Container>

	)
}

export default Navigation;