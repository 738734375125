import React, {useReducer, useState} from 'react';
import ReactDOM from 'react-dom';
import {useSpring, animated} from 'react-spring';
import axios from "axios";
import Data from "./WorkList.json";
import { Container, Row, Col, Hidden } from 'react-grid-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import { faIcons } from "@fortawesome/free-solid-svg-icons";
import { faPodcast } from "@fortawesome/free-solid-svg-icons";
import { faBezierCurve } from "@fortawesome/free-solid-svg-icons";

const projects = Data;

function openProject(e) {
    e.preventDefault();
    //buttonElement.classList.add("green");
}

 
function Work(){


	const [on, set] = React.useState(true)

	const [toggle, setToggle] = useState(null)

	const [buttonShow, showHide] = useReducer((visible) =>  !visible, true,);

	const projectObjects = projects.map((proj, i) => ({ id: i, title: proj.title, role: proj.role, tools: proj.tools, img1: proj.img1, type: proj.type}));

    const [state, setstate] = useState({data:""})

	const CloseButton = () => <button onClick={onClick}>CLOSE</button>

	const ContainerDisplay = () => {
		    const [flip, set] = useState(false)
  			const props = useSpring({ 
  				to: { opacity: 1 }, 
  				from: { opacity: 0 },
  				height: 100,
  				reset: true,
                reverse: flip,
                delay: 200,
                config: {duration:1000},
  				})	
  				return (
  					<>
  					<animated.div style={props} > 
  					<button onClick={() => {ProjectDisplay(toggle, projectObjects)}}>closer</button>

  					<p>{state.id}<br />
					{state.title}<br />
					{state.role}</p>
					<img src={state.img1} />
					
					</animated.div>
					
				</>
				)
	};

	const Text2 = () => (
  			//const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } })
  			<div>Same as it ever was</div>
	);

	const Text1 = () => {
  			const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } })
  			return <animated.div style={props} set={on}>
  				Something Else Like Text 1
  				</animated.div>
	};


	// const styles = useSpring({
	// 		loop: false,
 //    		delay: 3000,
 //    		config: {druation: 3000}
	// 	}) 


	const ProjectDisplay = (toggle, data) => {
		console.log("the toggle says " + toggle)
		setstate({id: (data.id), title: (data.title), role: (data.role), img1: (data.img1) })
		setToggle(true ? false: true)
		return (
		<>
		{toggle ? (
			<Text2 />
			) : (
			null
			)
		}
		</>
		)
	}
	


	const [showResults, setShowResults] = React.useState(true)
    const onClick = () => setShowResults(false)
    const Results = (props) => (
       <div id="results" className="search-results">

       <button style={{ height: '50px', width: '100px'}} type="submit" value="Input" onClick={onClick} />
       {/* {showResults ? <Results /> : null }*/}
         True Results
        </div>
      )
    const Noresults = () => (
    	<div> Not true results</div>
    	)

	let className = 'visible';

	const ChangeClassName = () => (
		alert('change')
	)


return( 

	
	<Container id="work" fluid>

	<Container>
	<Row>
		
	 <Col xs={12}><h2>Work</h2></Col>
	 <Col xs={12}><p>Below is a smattering of projects</p></Col>
	 <Col xs={12}></Col>
	 </Row>

	 <div>
      <input type="submit" value="Input" onClick={onClick} />
      { !showResults ? <Noresults /> : null }
    </div>

	<ContainerDisplay on={on} />
	{/*<animated.div style={proper}><p>I will animated</p><img src="https://via.placeholder.com/300x300/b52121/969696" /></animated.div>*/}
	
	{/*<animated.button id="closer" onClick={() => {showHide();}}>close</animated.button>*/}

	 {/* <Text1 on={on} />*/}
	 {/*{on ? "On" : "Off"}*/}
  

	 <Row id="FullProjectList" className={className}>
	 

	 	{projectObjects.map((proj) => (

	 		//removed from the Col below to test hidden/viz w/o classes
	 		// className={buttonShow ? "project visible": "project hidden"}

		<Col id={proj.id} xs={6} sm={4} md={3} lg={2} className="project">
		<button  onClick={() => {ProjectDisplay(on, proj)}} key={projectObjects.id} style={{backgroundImage: `url(${proj.img1})`}}>
		   <p><span>{proj.title}</span><br />
		   <span>{proj.tools}</span><br />
		   <span>{proj.type}</span></p>
		</button>
		</Col>

		))}
 
	 </Row>
	 


	
	</Container>
	</Container>
)
}

 export default Work;