import React from 'react'
import { Container, Row, Col, Hidden } from 'react-grid-system';

function Home(){
	return( 
	<Container id="skills" fluid>
	<Container>
	<Row  >
		
	 <Col><h2>Skills</h2></Col>
	 </Row>
	 <Row>

	 <Col xs={6} sm={3}>
	 	<h3>Web Technologies</h3>
	 	<p>HTML, HTML5</p>
	 	<p>CSS</p>
	 	<p>Sass, Less</p>
	 	<p>Javascript, JQuery, JSON, React.js</p>
	 	<p>PHP</p>
	 	<p>Drupal, Wordpress</p>
	 	<p>XML/XSL</p>
	 	<p>Git, Full-Stack Development</p>
	 </Col>

	 <Col xs={6} sm={3}>
	 	<h3>Design & Illustration</h3>
	 	<p>Photoshop</p>
	 	<p>Illustrator</p>
	 	<p>Indesign</p>
	 	<p>Lightroom</p>
	 	<p>Procreate, Adobe Draw (iPad)</p>
	 	<p>Print Layout & Design</p>
	 	<p>Sketchnoting</p>
	 	<p>Wireframing</p>
	 </Col>

	 <Col xs={6} sm={3}>
	 	<h3>Video</h3>
	 	<p>Filming, Lighting</p>
	 	<p>Editing</p>
	 	<p>Premiere Pro</p>
	 	<p>After Effects</p>
	 	<h3>Project Management</h3>
	 	<p>Waterfall</p>
	 	<p>Iterative/Agile</p>
	 	<p>Timeline Creation, Execution</p>
	 	<p>Talent Management</p>
	 </Col>
	 <Col xs={6} sm={3}>
	 	<h3>UI/UX</h3>
	 	<p>Adobe XD, Figma</p>
	 	<p>Rapid Prototyping</p>
	 	<p>Wireframing</p>
	 	<p>User Testing</p>
	 	<h3>Marketing</h3>
	 	<p>Campaign Development</p>
	 	<p>Analytics</p>

	 </Col>

	</Row>
	</Container>
	</Container>
	)
}

export default Home;